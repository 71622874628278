import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { UploadImageAsync } from "../../apis/api";
import { PushNotificationsModel } from "../../models/models";
import { PushNotifications } from "../../store/actions";

interface IProps {
  showModal: boolean;
  onHide: (b: boolean) => any;
}

const NotificationsModal = (props: IProps) => {
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [notification, setNotification] = useState<PushNotificationsModel>({
    body: "",
    title: "",
    titleEn: "",
    messageEn: "",
    linkId: null,
    linkType: null,
    imageUrl: "",
    persist: true,
  });
  const [linkType, setLinkType] = useState<string>("NONE");
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (linkType == "NONE" || notification.linkId) {
      dispatch(PushNotifications(notification));
    } else {
      alert("error, fill the linkId field");
    }
  };
  const onClear = () => {};

  useEffect(() => {
    setNotification((n) => ({ ...n, linkType: linkType }));
  }, [linkType]);


  return (
    <Modal
      size={"lg"}
      onHide={() => {
        props.onHide(false);
      }}
      show={props.showModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5>Fill The Notification Data</h5>
        </Modal.Title>
      </Modal.Header>
      <Row className={"px-4 py-4"}>
        <Col md={6}>
          <Form>
            <Form.Group controlId="title">
              <Form.Label>Title Ar</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                onChange={(e: any) =>
                  setNotification((n) => ({ ...n, title: e.target.value }))
                }
              />
            </Form.Group>
            <Form.Group controlId="titleEn">
              <Form.Label>Title En</Form.Label>
              <Form.Control
                  type="text"
                  placeholder=""
                  onChange={(e: any) =>
                      setNotification((n) => ({ ...n, titleEn: e.target.value }))
                  }
              />
            </Form.Group>
            <Form.Group controlId="body">
              <Form.Label>Body Ar</Form.Label>
              <Form.Control
                type="textarea"
                as="textarea"
                placeholder=""
                onChange={(e: any) =>
                  setNotification((n) => ({ ...n, body: e.target.value }))
                }
              />
            </Form.Group>
            <Form.Group controlId="messageEn">
              <Form.Label>Body En</Form.Label>
              <Form.Control
                  type="textarea"
                  as="textarea"
                  placeholder=""
                  onChange={(e: any) =>
                      setNotification((n) => ({ ...n, messageEn: e.target.value }))
                  }
              />
            </Form.Group>
          </Form>
        </Col>
        <Col md={6}>
          <Form.Group controlId="body">
            <Form.Label>Linking</Form.Label>

            <Row>
              <Col className={"d-flex flex-row justify-content-between"}>
                <Button
                  variant="info"
                  onClick={() => {
                    setLinkType("site");
                  }}
                >
                  Link Site
                </Button>
                <Button
                  variant="info"
                  onClick={() => {
                    setLinkType("event");
                  }}
                >
                  Link Event
                </Button>
                <Button variant="danger" onClick={onClear}>
                  Clear
                </Button>
              </Col>
            </Row>
            <Row className={"mt-1"}>
              <Col>
                {linkType === "site" && (
                  <>
                    {" "}
                    <Form.Label>
                      Site Id <small>from squidex</small>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e: any) =>
                        setNotification((n) => ({
                          ...n,
                          linkId: e.target.value,
                        }))
                      }
                    />
                  </>
                )}

                {linkType === "event" && (
                  <>
                    {" "}
                    <Form.Label>
                      Event Id <small>from squidex</small>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      onChange={(e: any) =>
                        setNotification((n) => ({
                          ...n,
                          linkId: e.target.value,
                        }))
                      }
                    />
                  </>
                )}
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="d-flex align-items-around justify-content-around ">
            <div>
              <Form.Label>persist</Form.Label>
              <Form.Control
                size="sm"
                type="checkbox"
                className=""
                onChange={(e: any) =>
                  setNotification((n) => ({ ...n, persist: e.target.checked }))
                }
              />
            </div>
            <div>
              <Form.Label>Notify Mobile</Form.Label>
              <Form.Control
                size="sm"
                type="checkbox"
                className=""
                onChange={(e: any) =>
                  setNotification((n) => ({
                    ...n,
                    sendToMobile: e.target.checked,
                  }))
                }
              />
            </div>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group controlId="body">
            <Form.Label>Image</Form.Label>

            {showUpload ? (
              <input
                className={"mx-1 btn outline-info f"}
                //variant={"outline-info"}
                type="file"
                onChange={(e) => {
                  UploadImageAsync(e, true, (e) => {
                    setNotification((n) => ({ ...n, imageUrl: e }));
                    setShowUpload(false);
                  });
                }}
              />
            ) : (
              <Button
                style={{}}
                className={"mx-1"}
                variant={"outline-info"}
                onClick={() => setShowUpload(true)}
              >
                Upload file{" "}
              </Button>
            )}
            {notification.imageUrl && (
              <img className="img-fluid my-1" src={notification.imageUrl} />
            )}
          </Form.Group>
        </Col>
      </Row>
      <Modal.Footer>
        <Button variant="info" onClick={onSubmit}>
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default NotificationsModal;

import * as React from "react";

import { Route } from "react-router-dom";

import history from "../history";
import { useSelector } from "react-redux";
import {AppStateModel} from "../models/appStateModel";

interface IProps {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}

const LoggedInRoute = ({
                           component: Component,

                           ...otherProps
                       }: IProps) => {
    const isAuthenticated = useSelector(
        (state: AppStateModel) => state?.Auth?.accessToken
    );


    if (!isAuthenticated) {
        history.push("/login");
    }
    return (
        <>
            <Route
                render={(otherProps: any) => (
                    <>
                        <Component {...otherProps} />
                    </>
                )}
            />
        </>
    );
};

export default LoggedInRoute;

import { UserProfileModel } from "../../../models/usersModels";
import { Button, Col, Row } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import UserModal from "./userModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetUser, setUser } from "../../../store/actions";
import { AppStateModel } from "../../../models/appStateModel";

import { BiBlock, BiShow } from "react-icons/bi";

interface IProps {
  users: UserProfileModel[];
  onBlockOrUnBlock: (id: string) => any;
}

const Modal = (visible: boolean, onClose: () => any) => {
  const user = useSelector((state: AppStateModel) => state.User);

  return <UserModal user={user} visible={visible} onClose={onClose} />;
};

const UsersTable = (props: IProps) => {
  const [showModal, setSHowModal] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      {Modal(showModal, () => {
        setSHowModal(false);
        dispatch(setUser({}));
      })}
      <BTable striped bordered hover size="sm">
        {console.log(props.users)}
        <thead>
          <tr>
            <th>{"Name"}</th>
            <th>{"E-mail"}</th>
            <th>{"Mobile"}</th>
            <th>{"Country"}</th>
            <th>{"Gender"}</th>
          </tr>
        </thead>

        <tbody>
          {props.users.map((row: UserProfileModel, i: any): any => (
            <tr key={i} className={row.blocked ? "bg-danger" : ""}>
              <td>
                <Col>
                  <Row>{`${row.firstName ?? ""}  ${row.lastName ?? ""}`}</Row>
                </Col>
              </td>
              <td>
                <Col>
                  <Row>{row.email ?? ""}</Row>
                </Col>
              </td>
              <td>
                <Col>
                  <Row>{row.mobile}</Row>
                </Col>
              </td>
              <td>
                <Col>
                  <Row>
                    {`${row?.country?.toString() ?? ""}  ${
                      row?.city?.toString() ?? ""
                    }`}
                  </Row>
                </Col>
              </td>
              <td>
                <Col>
                  <Row>{`${row.gender ?? ""} `}</Row>
                </Col>
              </td>
              <td style={{ maxWidth: 100 }}>
                <Row className="d-flex align-items-center justify-content-around ">
                  <Col
                    md={4}
                    className="d-flex align-items-center justify-content-center "
                  >
                    <Button
                      size="sm"
                      variant={!row.blocked ? "danger" : "success"}
                      onClick={() => {
                        props.onBlockOrUnBlock(row.id!);
                      }}
                    >
                      <BiBlock size={21} />
                    </Button>
                  </Col>

                  <Col
                    md={4}
                    className=" d-flex align-items-center justify-content-center"
                  >
                    <Button
                      size="sm"
                      variant={"info"}
                      onClick={() => {
                        dispatch(GetUser(row.id!, 1));
                        setSHowModal(true);
                      }}
                    >
                      <BiShow size={21} />
                    </Button>
                  </Col>
                </Row>
              </td>
            </tr>
          ))}
        </tbody>
      </BTable>
    </>
  );
};
export default UsersTable;

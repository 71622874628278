import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useDispatch} from 'react-redux';
//import { Login } from '../../Actions/authActions';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Login} from "../store/actions";

const LoginPage = () => {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    const dispatch = useDispatch();

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            submit();
        }
    };
    const submit = () => {
        dispatch(Login(username, password));
    }

    return (
        <Container style={{height: "100vh"}}>
            <Row style={{height: "80vh"}}>
                <Col lg={3}></Col>
                <Col
                    lg={6}
                    style={{display: "flex"}}
                    className="flex-column justify-content-center"
                >
                    <Card bg="white" border="white">
                        <Card.Header className="bg-white" style={{textAlign: "center"}}>
                            <img src='https://www.asfarplus.com/asfarlogo.png' alt="cgologo"
                                 height={80}/>
                        </Card.Header>

                        <Card.Body>

                            <Form>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>{'Email'}</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        placeholder={"Email"}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>{"Password"}</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        placeholder={"Password"}
                                    />
                                </Form.Group>
                                {/*<p className="forgot-password text-right">*/}
                                {/*     <a href="#">Forgot Password?</a>*/}
                                {/*</p>*/}
                                <div className="f-flex align-self-end align-items-end justify-content-end w-100 ">
                                    <Button
                                        variant="info"
                                        onClick={submit}
                                        className="align-self-end "
                                        style={{width: "100%"}}
                                    >
                                        {"Login"}
                                    </Button>
                                </div>

                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}></Col>
            </Row>
        </Container>
    );
}

export default LoginPage;


import {AppStateModel} from "../models/appStateModel";
import InitialAppState from "./initialState";

import {AUTHENTICATION_SUCCEEDED, SET_ALERT, SET_LOADING, SET_PAGED_USERS, SET_USER, UNAUTHENTICATE,} from "./types";

const appReducer = (state = InitialAppState, action: any): AppStateModel => {
    switch (action.type) {

        case SET_LOADING:
            return {
                ...state,
                isLoading: action.payload.loading,
            };
        case SET_ALERT:
            return {
                ...state,
                Alert: action.payload.alert,
            };
        case SET_PAGED_USERS:
            return {
                ...state,
                PagedUsers: action.payload.users
            }
        case SET_USER:
            return {
                ...state,
                User: action.payload.user
            }
        case UNAUTHENTICATE:
            return {
                ...state,
                Auth: undefined,
            }
        case AUTHENTICATION_SUCCEEDED:
            console.log("red", action.payload)
            return {
                ...state,
                Auth: {
                    accessToken: action.payload.accessToken,
                    isAuthenticated: true,
                }
            };


        default:
            return state;
    }
};
export default appReducer;

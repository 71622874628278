import moment from "moment-timezone";

const ToLocalDateTime=(date:any)=>{

    if(!date){
        return ""
    }
    let a = moment.utc(date)
    a.tz('Asia/Amman')
    return a.format('YYYY/MM/DD, hh:mm a');

}
export default ToLocalDateTime

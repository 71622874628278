import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import AppReducer from "./reducer";
import logger from "redux-logger";

const configureStore = () => {
    return createStore(AppReducer, applyMiddleware(thunkMiddleware, logger));
};

export default configureStore;

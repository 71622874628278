import { useDispatch, useSelector } from "react-redux";
import { AppStateModel } from "../../models/appStateModel";
import { useEffect, useState } from "react";
import { BlockUser, ClearCache, SearchUsers } from "../../store/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { BiSearchAlt } from "react-icons/bi";
import UsersTable from "./components/usersTable";
import { UsersSearchModel } from "../../models/usersModels";
import Pager from "../components/pager";
import NotificationsModal from "../components/notificationsModal";
import { UploadImageAsync } from "../../apis/api";
const UsersPage = () => {
 

  const dispatch = useDispatch();

  const pagedUsers = useSelector((state: AppStateModel) => state.PagedUsers);
  const [searchCriteria, setSearchCriteria] = useState<UsersSearchModel>({pageNumber:1});
  const [nameSearch, setNameSearch] = useState<string>("");
  const [mobileSearch, setMobileSearch] = useState<string>("");
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(SearchUsers(searchCriteria));
  }, [dispatch, searchCriteria,searchCriteria.pageNumber]);
  const handleKeyPress = (event: any, callback: () => any) => {
    if (event.key === "Enter") {
      callback();
    }
  };

 
  return (
    <div style={{ padding: "1vw" }}>
      <Card>
        <Card.Header>
          <Row className="px-md-2">
            <Col lg={3}>
              <InputGroup>
                <Form.Control
                  style={{ height: 42 }}
                  size="sm"
                  placeholder={"Name"}
                  value={nameSearch}
                  onChange={(e) => setNameSearch(e.target.value)}
                  onKeyPress={(e: any) =>
                    handleKeyPress(e, () => {
                      setSearchCriteria({
                        ...searchCriteria,
                        name: nameSearch,
                      });
                    })
                  }
                />
                <InputGroup.Append>
                  <Button
                    variant={"info"}
                    onClick={() => {
                      setSearchCriteria({
                        ...searchCriteria,
                        name: nameSearch,
                      });
                    }}
                    className="icon-button"
                  >
                    <BiSearchAlt size={27} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>

            <Col lg={3} className="px-md-1">
              <InputGroup>
                <Form.Control
                  style={{ height: 42 }}
                  size="sm"
                  placeholder={"Phone"}
                  value={mobileSearch}
                  onChange={(e) => setMobileSearch(e.target.value)}
                  onKeyPress={(e: any) =>
                    handleKeyPress(e, () =>
                      setSearchCriteria({
                        ...searchCriteria,
                        mobile: mobileSearch,
                      })
                    )
                  }
                />
                <InputGroup.Append>
                  <Button
                    variant={"info"}
                    onClick={() =>
                      setSearchCriteria({
                        ...searchCriteria,
                        mobile: mobileSearch,
                      })
                    }
                    className="icon-button"
                  >
                    <BiSearchAlt size={27} />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col lg={6} className="">
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{}}
                  className={"mx-1"}
                  variant={"outline-info"}
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Send Notifications
                </Button>

                <Button
                  style={{}}
                  className={"mx-1"}
                  variant={"outline-danger"}
                  onClick={() => dispatch(ClearCache())}
                >
                  Clear cache
                </Button>
                {showUpload ? (
                  <input
                    className={"mx-1 btn outline-info f"}
                    //variant={"outline-info"}
                    type="file"
                    onClick={(e) => UploadImageAsync(e)}                 />
                ) : (
                  <Button
                    style={{}}
                    className={"mx-1"}
                    variant={"outline-info"}
                    onClick={() => setShowUpload(true)}
                  >
                    Upload file{" "}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Card.Header>

        <Card.Body>
          {pagedUsers.matches ? (
            <UsersTable
              onBlockOrUnBlock={(i) => {
                dispatch(BlockUser(i));
              }}
              users={pagedUsers.matches}
            />
          ) : (
            <></>
          )}

          {pagedUsers && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pager
                currentPageNumber={searchCriteria?.pageNumber ?? 1}
                numberOfPages={Math.ceil(pagedUsers.total! / 15)}
                onChange={(page: number) => {
                  setSearchCriteria({ ...searchCriteria, pageNumber: page });
                }}
              />
            </div>
          )}
        </Card.Body>
      </Card>
      <NotificationsModal
        onHide={() => {
          setShowModal(false);
        }}
        showModal={showModal}
      />
    </div>
  );
};

export default UsersPage;

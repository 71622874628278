import {ApiResponse, ClientFactory} from "@simplify9/simplyapiclient";
import {DataResponse, PagingModel,} from "@asfar/asfarplusclientjs";
import {UserProfileModel, UsersSearchModel} from "../models/usersModels";
import {PushNotificationsModel} from "../models/models";
import Axios from 'axios'

const client = ClientFactory();
export const UploadImageAsync = async (url: any, hideAlert?: boolean, onUpload?: (e: string) => void) => {
    const serverAxios = Axios.create();

    let formData = new FormData();
    const file = url.target.files[0];
    console.log(url.target.value);
    if (file) {
        //@ts-ignore
        formData.append(
            "File",
            //@ts-ignore
            file
        );

        const uploadImageUrl = "https://www.asfarplus.com/api/v1/blobs";

        const headers = {
            "Content-Type": "multipart/form-data",
        };
        try {
            let res = await serverAxios({
                method: "post",
                url: uploadImageUrl,
                data: formData,
                headers: headers,
            });
            if (onUpload) {
                onUpload(res.data.location)
            }
            if (!hideAlert) {

                alert(res.data.location);

            } else {

            }
        } catch (error: any) {
            return error?.response;
        }
    } else {
        alert("Something went wrong")
    }

};
export const PushNotificationsAsync = async (data: PushNotificationsModel) => {
    const res = await client.SimplyPostAsync("Admin/PushNotification", data);
    return res;
};
export const SearchNotificationsAsync = async (data: any) => {
    const res = await client.SimplyGetAsync(`Admin/SearchNotifications`);

    return res.data;
};
export const ExportNotificationsAsync = async () => {
    const res = await client.SimplyGetAsync(`Admin/SearchNotification?excel=true`);

    return res.data;
};
export const LoginAsync = async (username: string, password: string) => {
    const res: ApiResponse = await client.SimplyPostAsync("admin/login", {
        email: username,
        password: password,
    });
    return res;
};

export const ClearCacheAsync = async () => {
    const res: ApiResponse = await client.SimplyPostAsync("admin/clearCache", {});
    return res;
};

export const BlockUserAsync = async (id: string) => {
    const res: ApiResponse = await client.SimplyPostAsync(
        "admin/BlockOrUnblockuser",
        {id: id}
    );
    return res;
};

export const SearchUsersAsync = async (
    model: UsersSearchModel
): Promise<DataResponse<PagingModel<UserProfileModel>>> => {
    let q: string = "?";
    model.mobile && (q += `mobile=${model.mobile}&`);
    model.name && (q += `name=${model.name}&`);
    const offset = ((model?.pageNumber - 1) ?? 0) * 15;
    const res: ApiResponse = await client.SimplyGetAsync(`admin/SearchUsers${q}offset=${offset}&limit=15`);
    return res;
};

export const GetUserAsync = async (
    id: string,
    pageNumber: number
): Promise<DataResponse<UserProfileModel>> => {
    const offset: number = (pageNumber - 1) * 8;
    const res: ApiResponse = await client.SimplyGetAsync(
        `admin/${id}/GetUser?limit=${8}&offset=${offset}`
    );
    return res;
};

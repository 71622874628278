import {Switch} from 'react-router-dom';

import LoggedOutRoute from "./LoggedOutRoute";
import LoggedInRoute from "./LoggedInRoute";
import LoginPage from "../pages/loginPage";
import UsersPage from '../pages/users/users';
import Notifications from "../pages/notifications";


const Pages = () => {

    return (

        <Switch>
            <LoggedOutRoute path="/login" exact={true} component={LoginPage}/>
            <LoggedInRoute path="/" exact={true} component={UsersPage}/>
            <LoggedInRoute path="/users" exact={true} component={UsersPage}/>
            <LoggedInRoute path="/notifications" exact={true} component={Notifications}/>


        </Switch>
    );
};
export default Pages;

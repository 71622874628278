import { FunctionComponent, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  ExportNotificationsAsync,
  SearchNotificationsAsync,
} from "../../apis/api";
import Pager from "../components/pager";
import { UsersSearchModel } from "../../models/usersModels";
import { PagingModel } from "@asfar/asfarplusclientjs";
import BTable from "react-bootstrap/Table";

const Notifications: FunctionComponent = () => {
  const [pagedNotifications, setPagedNotifications] =
    useState<PagingModel<any>>();
  const [searchCriteria, setSearchCriteria] = useState<UsersSearchModel>({
    pageNumber: 1,
  });

  const exportToExcel = async () => {
    const res = await ExportNotificationsAsync();
    if (res) {
      window.open(res.location, "_blank", "noreferrer");
    }
  };
  useEffect(() => {
    (async () => {
      const res = await SearchNotificationsAsync({});
      if (res) {
        setPagedNotifications(res);
      }
    })();
  }, []);
  console.log(pagedNotifications);
  return (
    <div>
      <div>
        <Card>
          <Card.Header>
            <Row className="px-md-2">
              <Col lg={6} className="">
                <div>
                  <Button
                    style={{}}
                    className={"mx-1"}
                    variant={"outline-info"}
                    onClick={exportToExcel}
                  >
                    Export to excel Notifications
                  </Button>
                </div>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body>
            {pagedNotifications?.matches ? (
              <BTable striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>{"Id"}</th>
                    <th>{"Title"}</th>
                    <th>{"Persisted"}</th>
                    <th>{"Body"}</th>
                    <th>{"LinkType"}</th>
                    <th>{"ImageUrl"}</th>
                    <th>{"LinkId"}</th>
                  </tr>
                </thead>

                <tbody>
                  {pagedNotifications?.matches?.map((row: any, i: any): any => (
                    <tr key={i} className={row.blocked ? "bg-danger" : ""}>
                      <td>
                        <Col>
                          <Row>{row.id}</Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>{row.title}</Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>{row.persisted ? "Yes" : "No"}</Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>{row.message}</Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>{row.linkType}</Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>
                            {row.imageUrl && (
                              <>
                                {" "}
                                <a target={"_blank"} href={row.imageUrl}>
                                  ImageUrl
                                </a>
                              </>
                            )}
                          </Row>
                        </Col>
                      </td>
                      <td>
                        <Col>
                          <Row>{row.linkId}</Row>
                        </Col>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </BTable>
            ) : (
              <></>
            )}

            {pagedNotifications && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pager
                  currentPageNumber={searchCriteria?.pageNumber ?? 1}
                  numberOfPages={Math.ceil(pagedNotifications?.total! / 30)}
                  onChange={(page: number) => {
                    setSearchCriteria({ ...searchCriteria, pageNumber: page });
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Notifications;

import React from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history  from "./history"
import configureStore from "./store";
import AppLoader from './AppLoader';
import AppNav from "./store/navBar";

const App = () => {
    const store = configureStore();

    return (
        <Provider store={store}>

            <Router history={history}>

                <AppLoader/>

            </Router>
        </Provider>
    );
}

export default App;

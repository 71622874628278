import {AppStateModel} from "../models/appStateModel";

const InitialAppState: AppStateModel = {
    Alert: {
        visible: false,

    },
    isLoading: false,
    Auth: {
        isAuthenticated: false,
        accessToken: "",
        refreshToken: ""
    },
    PagedUsers: {
        limit:20,
        offset:0,
        matches:[],
        total:0

    }

}

export default InitialAppState;

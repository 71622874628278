import {Button, Card, Col, Image, Modal, Row} from "react-bootstrap";
import {PostModel, UserProfileModel} from "../../../models/usersModels";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {HidePost} from "../../../store/actions";
import ToLocalDateTime from "../../../Services/date";
import Pager from "../../components/pager";

interface IProps {
    user?: UserProfileModel | undefined;
    visible: boolean;
    onClose: () => any;
}

const UserModal = (props: IProps) => {

    const dispatch = useDispatch()
    const [pageNumber, setPageNumber] = useState<number>(1)

    const onHidePost = (id: string | undefined) => {

        if (id) {
            dispatch(HidePost(id))

        }
    }
    if (props.user?.id) {
        return (
            <Modal size={"xl"} show={props.visible}>
                <Card>
                    <Card.Header>
                        <Row className="justify-content-end p-2 pt-0">
                            <div
                                style={{
                                    backgroundColor: "#FF605C",
                                    borderRadius: 50,
                                    width: 27,
                                    height: 27,
                                    display: "flex"
                                }}
                                onClick={props.onClose}
                                className="justify-content-center align-items-center px-1 "
                            >
                                <span style={{color: "grey", cursor: "default"}}>x</span>

                            </div>
                        </Row>
                        <div className="">
                            <Row>
                                <Col md={4}>
                                    <Image
                                        src={props.user?.imageUrl ?? ""}
                                        className="img-fluid rounded"
                                    />
                                </Col>
                                <Col>
                                    <Row>
                                        <Col md={4}>
                                            <p className="text-lg-left text h4">
                                                {"Name: "}
                                                {`${props.user?.firstName} ${props.user?.lastName}`}
                                            </p>
                                        </Col>
                                        <Col md={4}>
                                            <p className="text-lg-left text h4">
                                                {"Gender: "}
                                                {`${props.user?.gender} `}
                                            </p>
                                        </Col>
                                        <Col md={4}>
                                            <p className="text-lg-left text h4">
                                                {"Mobile: "}
                                                {`${props.user?.mobile}`}
                                            </p>
                                        </Col>
                                        <Col md={4}>
                                            <p className="text-lg-left text h4">
                                                {"Email: "}
                                                {`${props.user?.email ?? ""} `}
                                            </p>
                                        </Col>
                                        <Col md={4}>
                                            <p className="text-lg-left text h4">
                                                {"Country: "}
                                                {`${props.user?.country ?? ""}`}
                                            </p>
                                        </Col>
                                        <Col md={4}>
                                            <p className="text-lg-left text h4">
                                                {"City: "}
                                                {`${props.user?.city ?? ""}`}
                                            </p>
                                        </Col>
                                        <Col md={4}>
                                            <p className="text-lg-left text h4">
                                                {"Bio: "}
                                                {`${props.user?.bio ?? ""}`}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title>Posts</Card.Title>
                        <Row className="d-flex ">
                            {props.user?.posts?.map((p: PostModel) => {
                                return (
                                    <Col className="p-2 " md={3}>
                                        <Card border="info" bg="light">
                                            <Card.Img
                                                variant="top"
                                                className="img-fluid "
                                                style={{height: 271}}
                                                src={p?.imageUrls![0] ?? ""}
                                            />
                                            <Card.Body style={{minHeight: 120}}>
                                                <Card.Title className="text-info font-weight-bold">
                                                    {p.title}
                                                </Card.Title>
                                                <Card.Text>{p.description}</Card.Text>
                                            </Card.Body>
                                            <Card.Footer className={"d-flex flex-row justify-content-between"}>
                                                <div>
                                                    <small className="text-muted">
                                                        {ToLocalDateTime(p.createdOn)}
                                                    </small>
                                                </div>

                                                <div>
                                                    <Button size={"sm"} variant={"danger"}
                                                            onClick={() => onHidePost(p.id as unknown as string)}>Hide</Button>
                                                </div>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                );
                            })}
                        </Row>
                        <Row className="d-flex justify-content-center align-content-center">
                            <Pager
                                currentPageNumber={pageNumber}
                                numberOfPages={Math.ceil(props.user?.posts?.length! / 6)}
                                onChange={(page: number) => {
                                }}/>
                        </Row>

                        <Row className="pt-4 px-4 d-flex justify-content-end">
                            <Button variant="info" onClick={props.onClose}>
                                Close
                            </Button>
                        </Row>
                    </Card.Body>
                </Card>
            </Modal>
        );
    } else {
        return <></>;
    }
};

export default UserModal;

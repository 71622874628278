import {
    AUTHENTICATION_FAILED,
    AUTHENTICATION_SUCCEEDED,
    SET_LOADING,
    SET_PAGED_USERS,
    SET_USER,
    UNAUTHENTICATE,
} from "./types";
import {deleteAllCookies, getAccessToken, setAccessToken} from "../Services/cookiesManager";
import * as api from "../apis/api";
import {GetProfile} from "@asfar/asfarplusclientjs/dist/services/profileServices";
import {UserProfileModel, UsersSearchModel} from "../models/usersModels";
import {HidePostAsync, PagingModel} from "@asfar/asfarplusclientjs";
import {PushNotificationsModel} from "../models/models";
import {AppStateModel} from "../models/appStateModel";

export const PushNotifications = (model: PushNotificationsModel) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        await api.PushNotificationsAsync({...model});
        dispatch(setLoading(false));
    };
};

export const SearchUsers = (model: UsersSearchModel) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));

        const res = await api.SearchUsersAsync(model);
        if (res.status == 401) {
            dispatch(Logout())
        }
        if (res.succeeded && res.data) {
            dispatch(setPagedUsers(res.data));
        }

        dispatch(setLoading(false));
    };
}
export const Logout = () => {
    return async (dispatch: any, getState: any) => {
        deleteAllCookies();
        dispatch(Unauthenticate());
    };
};
export const GetUser = (id: string, pageNumber: number) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const res = await api.GetUserAsync(id, pageNumber);
        if (res.succeeded && res.data) {
            dispatch(setUser(res.data));
        }

        dispatch(setLoading(false));
    };
};

export const BlockUser = (id: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const res = await api.BlockUserAsync(id);
        if (res.succeeded) {
            dispatch(SearchUsers({pageNumber: 1}));
        }

        dispatch(setLoading(false));
    };
};
export const HidePost = (id: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const state: AppStateModel = getState()
        const res = await HidePostAsync(id, state.Auth?.accessToken as string);
        console.log(res)
        if (res.succeeded) {

        }

        dispatch(setLoading(false));
    };
};
export const Login = (username: string, password: string) => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        //AsfarPlus123!
        const response = await api.LoginAsync(username, password);

        if (response.succeeded) {
            const access = response.data.accessToken;

            setAccessToken(access);
            dispatch(authenticationSucceed(access));

        } else {

            dispatch(authenticationFailed());
        }
    };
};

export const CompleteLogIn = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const res = await GetProfile();
        if (res.succeeded) {
            // dispatch(
            //     memberInfoRetrieved(res.data)
            // );
        } else {
            dispatch(Unauthenticate());
        }
    };
};

export const ClearCache = () => {
    return async (dispatch: any, getState: any) => {
        await api.ClearCacheAsync();
    };
};
export const CheckAuthenticationAsync = () => {
    return async (dispatch: any, getState: any) => {
        dispatch(setLoading(true));
        const accessToken = getAccessToken();
        if (accessToken !== null && accessToken !== "") {
            dispatch(authenticationSucceed(accessToken));
        } else {
            dispatch(Unauthenticate());
        }
    };
};
const authenticationSucceed = (accessToken: string) => {
    return {
        type: AUTHENTICATION_SUCCEEDED,
        payload: {
            accessToken: accessToken,
        },
    };
};
export const Unauthenticate = () => {
    return {
        type: UNAUTHENTICATE,
    };
};

export const setLoading = (loading: boolean) => {
    return {
        type: SET_LOADING,
        payload: {
            loading,
        },
    };
};

export const setUser = (user: UserProfileModel) => {
    return {
        type: SET_USER,
        payload: {
            user,
        },
    };
};
const authenticationFailed = () => {
    return {
        type: AUTHENTICATION_FAILED,
    };
};

const setPagedUsers = (users: PagingModel<UserProfileModel>) => {
    return {
        type: SET_PAGED_USERS,
        payload: {
            users,
        },
    };
};

// tslint:disable:interface-name
// eslint-disable-next-line
import { createBrowserHistory, Location } from "history";
declare global {
    interface Window {
        dataLayer: any;
    }
}
const history = createBrowserHistory();

export default history;

import React from "react";
import "./App.css";
import { Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Router } from "react-router-dom";
import Pages from "./Routes/Pages";
import { useDispatch, useSelector } from "react-redux";
import { SetClientConfig } from "@simplify9/simplyapiclient";
import { getAccessToken } from "./Services/cookiesManager";
import { CheckAuthenticationAsync, Unauthenticate } from "./store/actions";
import AppNav from "./store/navBar";
import { AppStateModel } from "./models/appStateModel";
import { baseUrl } from "./env";
//import { baseUrl } from './env-prod';

const App = () => {
  const isAuthenticated = useSelector(
    (state: AppStateModel) => state?.Auth?.accessToken
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    SetClientConfig({
      authType: "bearer",
      baseUrl: baseUrl,
      getBearer: () => getAccessToken(),
      onAuthFail: () => {
        dispatch(Unauthenticate());
      },
    });
    dispatch(CheckAuthenticationAsync());
  }, [dispatch]);
  return (
    <>
      {isAuthenticated && <AppNav />}

      <Container
        fluid
        className=" justify-content-center "
        style={{
          maxWidth: 1600,
          width: "100%",
          alignSelf: "center",
          justifyContent: "center",
          alignItems: "center",
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <Row className="pt-4 w-100"></Row>
        <Route component={Pages} />
      </Container>
    </>
  );
};
export default App;

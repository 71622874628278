import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {useDispatch} from "react-redux";
import Form from "react-bootstrap/Form";
//import {LogOutAsync} from "./Actions/authActions";
//import AppStateModel from "./Models/appStateModel";
import {NavLink as RouterLink} from "react-router-dom";
import {Image} from "react-bootstrap";
import Notifications from "../pages/notifications";

export const AppNav = () => {
    //const profile = useSelector((state: AppStateModel) => state.Profile);
    // const [showUpdatePasswordModal,setShowUpdatePasswordModal]= useState(false);
    const dispatch = useDispatch();


    return (
        <Navbar
            className="justify-content-between"
            expand="lg"
            style={{
                padding: "0px 2vw 0px 2vw", backgroundColor: "#fff"
            }}
        >
            <Navbar.Brand href="/">
                <Image src="/asfarlogo.png" height={55}/>

            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className=" mx-2" variant={"tabs"}>
                    <RouterLink className="nav-link" exact={true} to="/users">
                        {"Users"}
                    </RouterLink>
                </Nav>
                <Nav className=" mx-2" variant={"tabs"}>
                    <RouterLink className="nav-link" exact={true} to="/notifications">
                        {"Notifications"}
                    </RouterLink>
                </Nav>

                {/* <Nav.Item>*/}
                {/*    <Button onClick={() => dispatch(LogOutAsync())}*/}
                {/*            variant="outline-danger"*/}
                {/*    >*/}
                {/*        {"Logout"}*/}
                {/*    </Button>*/}
                {/*</Nav.Item>*/}

            </Navbar.Collapse>
            <Form inline></Form>
        </Navbar>
    );
};

export default AppNav;

import * as React from "react";
import { Route } from "react-router-dom";
import history from "../history";
import { useSelector } from "react-redux";
import {AppStateModel} from "../models/appStateModel";

interface IProps {
    exact?: boolean;
    path: string;
    component: React.ComponentType<any>;
}

const LoggedOutRoute = ({
                            component: Component,
                            ...otherProps
                        }: IProps) => {
    const isAuthenticated = useSelector(
        (state: AppStateModel) => state?.Auth?.accessToken
    );

    // React.useEffect(() => {
    // 	history.push("/");
    // },[])

    if (isAuthenticated) {
        history.push("/");
    }
    return (
        <>
            <Route
                render={(otherProps) => (
                    <>
                        <Component {...otherProps} />
                    </>
                )}
            />
        </>
    );
};

export default LoggedOutRoute;
